import Button from 'aac-components/components/Button';
import {BREAKPOINT, MAX_WIDTH_PX} from 'aac-components/utils/styles';
import React from 'react';
import TopProviders from '../TopProviders';

const InsuranceSection = () => {
    return (
        <section className="insurance-section">
            <h2>Check Your Coverage</h2>
            <p>
                Check to see if your insurance is in-network at an American Addiction
                Centers location.
            </p>
            <div className="insurance-section__button">
                <Button
                    style={{minWidth: '100%', fontWeight: 'normal'}}
                    theme="invert"
                    onClick={(e) =>
                        window && window.AAC && window.AAC.openSlideInSsvob(e)
                    }>
                    Check Coverage Now
                </Button>
            </div>
            <TopProviders isPaymentOptionsPage={false} />
            <style jsx>
                {`
                    .insurance-section {
                        margin: 0 auto;
                        max-width: ${MAX_WIDTH_PX};
                        padding: 64px 15px;
                        text-align: center;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .insurance-section {
                            padding: 120px 0;
                        }
                    }
                    .insurance-section__button {
                        max-width: 100%;
                        margin: 0 auto 40px auto;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .insurance-section__button {
                            max-width: fit-content;
                        }
                    }
                `}
            </style>
        </section>
    );
};
export default InsuranceSection;
