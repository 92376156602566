import {BREAKPOINT} from 'aac-components/utils/styles';
import Link from 'next/link';
import React from 'react';
import Image from 'next/image';
import styled from 'styled-components';

const InsuranceSectionProvidersStyled = styled.div`
    display: ${(props) => (props.isPaymentOptionsPage ? 'flex' : 'grid')};
    grid-template-columns: ${(props) => (props.isPaymentOptionsPage ? '' : 'auto auto')};
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: ${(props) => (props.isPaymentOptionsPage ? '16px' : '16px 40px')};

    /* used to adjust ambetter image */
    a:nth-child(2) {
        position: ${(props) => (props.isPaymentOptionsPage ? 'relative' : '')};
        left: ${(props) => (props.isPaymentOptionsPage ? '20px' : '')};
    }

    @media screen and (min-width: ${BREAKPOINT}) {
        display: flex;
        grid-template-columns: ;
        justify-content: center;
        align-items: center;
        padding-bottom: 0;
        overflow: hidden;

        /* used to adjust ambetter image */
        a:nth-child(2) {
            position: ${(props) => (props.isPaymentOptionsPage ? '' : '')};
            left: ${(props) => (props.isPaymentOptionsPage ? '20' : '')};
        }
    }
`;

const InsuranceSectionImagesStyled = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    min-width: ${(props) => (props.isPaymentOptionsPage ? '80px' : 'auto')};
    height: ${(props) => (props.isPaymentOptionsPage ? '50px' : 'auto')};
    width: ${(props) => (props.isPaymentOptionsPage ? '100%' : 'auto')};
    text-align: center;
    margin: 0 auto;
`;

const TopProviders = ({isPaymentOptionsPage = false}) => {
    const commonsProviderFolder =
        'https://americanaddictioncenters-org.s3.us-west-2.amazonaws.com/common/providers';

    const providerImageStyles = isPaymentOptionsPage
        ? {
              position: 'absolute',
              width: 'auto',
              height: '100%',
              left: '0',
              right: '0',
              bottom: '0',
              objectFit: 'contain',
              color: 'transparent',
          }
        : {width: 'auto', height: 'auto', maxWidth: '120px'};

    const providerCards = [
        {
            title: 'Aetna',
            image: `${commonsProviderFolder}/aetna.svg`,
            href: `/insurance-coverage/aetna`,
            style: {...providerImageStyles},
            altText: 'Aetna Logo',
        },
        {
            title: 'Ambetter',
            image: `${commonsProviderFolder}/ambetter.svg`,
            href: `/insurance-coverage/ambetter`,
            style: {...providerImageStyles},
            altText: 'Ambetter Logo',
        },
        {
            title: 'Anthem',
            image: `${commonsProviderFolder}/anthem-2.png`,
            href: '/insurance-coverage/anthem',
            style: {...providerImageStyles},
            altText: 'Anthem Logo',
        },
        {
            title: 'Blue Cross Blue Shield',
            image: `${commonsProviderFolder}/anthem-bcbs.svg`,
            href: `/insurance-coverage/blue-cross-blue-shield-association`,
            style: {...providerImageStyles},
            altText: 'Blue Cross Blue Shield Logo',
        },
        {
            title: 'Carelon Behavioral Health Options',
            image: `${commonsProviderFolder}/carelon.svg`,
            href: '/insurance-coverage/carelon-behavioral-health',
            style: {...providerImageStyles},
            altText: 'Carelon Behavioral Health Options Logo',
        },
        {
            title: 'Cigna',
            image: `${commonsProviderFolder}/cigna.svg`,
            href: `/insurance-coverage/cigna-insurance`,
            style: {...providerImageStyles},
            altText: 'Cigna Logo',
        },
        {
            title: 'Health Net',
            image: `${commonsProviderFolder}/health-net.png`,
            href: `/insurance-coverage/health-net`,
            style: {...providerImageStyles},
            altText: 'Health Net',
        },
        {
            title: 'Kaiser Permanente',
            image: `${commonsProviderFolder}/kaiser.png`,
            href: `/insurance-coverage/kaiser-permanente`,
            style: {...providerImageStyles, width: '95px'},
            altText: 'Kaiser Permanente',
        },
        {
            title: 'Magellan Health',
            image: `${commonsProviderFolder}/magellan-health.png`,
            href: `/insurance-coverage/magellan-insurance`,
            style: {...providerImageStyles, width: '75px'},
            altText: 'Magellan Health Logo',
        },
        {
            title: 'Oscar Health Plan',
            image: `${commonsProviderFolder}/oscar.png`,
            href: `/insurance-coverage/oscar-health-plan`,
            style: {...providerImageStyles},
            altText: 'Oscar Health Plan Logo',
        },
        {
            title: 'TRICARE',
            image: `${commonsProviderFolder}/tricare-2.png`,
            href: `/insurance-coverage/tricare`,
            style: {...providerImageStyles},
            altText: 'TRICARE Logo',
        },
        {
            title: 'Tufts',
            image: `${commonsProviderFolder}/tufts.svg`,
            href: `/insurance-coverage/tufts-health-plan`,
            style: {...providerImageStyles},
            altText: 'Tufts Logo',
        },
        {
            title: 'United Healthcare',
            image: `${commonsProviderFolder}/united.svg`,
            href: `/insurance-coverage/unitedhealth-group`,
            style: {...providerImageStyles},
            altText: 'United Healthcare Logo',
        },
        {
            title: 'VA Benefits (Veterans Affairs)',
            image: `${commonsProviderFolder}/va-2.png`,
            href: `/insurance-coverage/va-benefits`,
            style: {...providerImageStyles},
            altText: 'VA Benefits (Veterans Affairs) Logo',
        },
    ];

    return (
        <InsuranceSectionProvidersStyled isPaymentOptionsPage={isPaymentOptionsPage}>
            {providerCards.map((card) => {
                return (
                    <React.Fragment key={card?.image}>
                        <Link href={card?.href} title={card?.title}>
                            <InsuranceSectionImagesStyled
                                isPaymentOptionsPage={isPaymentOptionsPage}>
                                <Image
                                    src={card?.image}
                                    width={0}
                                    height={0}
                                    style={card.style}
                                    sizes="200px"
                                    alt={card.altText}
                                />
                            </InsuranceSectionImagesStyled>
                        </Link>
                    </React.Fragment>
                );
            })}
        </InsuranceSectionProvidersStyled>
    );
};

export default TopProviders;
